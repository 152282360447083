import Box from "@mui/material/Box";

import { usePageView } from "src/hooks/use-page-view";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { IApproval } from "src/services/thumbz-base-api";
import { ApprovalTabApproval } from "src/sections/approvals/approval-tab-approval";
import { ApprovalRevision } from "src/sections/approvals/approval-revision";
import ApprovalTabCustomersCard from "src/sections/approvals/approval-tab-customers-card";
import { ApprovalComplement } from "src/sections/approvals/approval-complement";

interface IAprovacaoPageProps {
  wrk_id: string;
  apv_id: string;
  activeStep: number;
  approval: IApproval;
  onNext: () => void;
  onBack: () => void;
}

export const ApprovalContent: React.FC<IAprovacaoPageProps> = ({
  wrk_id,
  apv_id,
  activeStep,
  approval,
  onNext,
  onBack,
}) => {
  usePageView();

  if (!apv_id) {
    return <EmptyPlaceholder overlineText="Nenhum espaço de trabalho selecionado" />;
  }

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
        <ApprovalTabApproval wrk_id={wrk_id} approval={approval} />
      </Box>
      {activeStep === 1 && (
        <ApprovalTabCustomersCard
          approval={approval}
          onClose={onBack}
          onSave={onNext}
          open
          wrk_id={wrk_id}
        />
      )}
      {/* {activeStep === 2 && <ApprovalComplement approval={approval} />} */}
      {activeStep === 2 && <ApprovalRevision approval={approval} />}
    </Box>
  );
};
