import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTheme } from "@mui/material/styles";
import { IApproval, IStatusType } from "src/services/thumbz-base-api";
import { ApprovalViewProps } from "./approval-view-selector";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import {
  Card,
  CardHeader,
  Typography,
  Grid,
  Box,
  Stack,
  AvatarGroup,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { RenderAvatar } from "src/components/avatar/RenderAvatar";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate } from "@tanstack/react-router";
import { MoreVert } from "@mui/icons-material";

// Tipagem para os dados de Kanban
interface KanbanCardData extends IApproval {}

export const ApprovalKanban: React.FC<ApprovalViewProps> = ({ onDelete, approvals, config }) => {
  const navigate = useNavigate();
  const { api } = useThumbzApi();
  const theme = useTheme(); // Detectar o tema atual (light ou dark)
  const [columns, setColumns] = useState<IStatusType[]>([]);

  const statusQuery = useQuery({
    queryKey: queryKeys.status.statusControllerFindAll().queryKey,
    queryFn: () => api.status.statusControllerFindAll(),
  });

  useEffect(() => {
    if (statusQuery.data) {
      setColumns(statusQuery.data);
    }
  }, [statusQuery.data]);

  function handleCardClick({ apv_id, wrk_id }: { apv_id: string; wrk_id: string }) {
    navigate({
      to: `/workspaces/$wrk_id/aprovacao/$apv_id`,
      params: { wrk_id, apv_id },
    });
  }

  // Transformando os dados de approvals para o formato que o Kanban espera
  const kanbanData: KanbanCardData[] = approvals;

  const KanbanCard: React.FC<KanbanCardData> = (approval) => {
    const { apv_id, apv_name, apv_created_at, stages, workspace } = approval;
    const { wrk_id } = workspace;
    const { show_apv_created_at, show_apv_name, show_stages, show_workspace } = config;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const allCustomers = stages.flatMap(({ approvalStageCustomers }) => approvalStageCustomers);
    const currentStage = stages
      .sort((a, b) => a.stg_order - b.stg_order)
      .find(({ stg_completed }) => !stg_completed);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      handleMenuClose();
      onDelete(approval, approval.workspace.wrk_id);
    };

    return (
      <Card
        variant="elevation"
        elevation={3}
        onClick={() => handleCardClick({ apv_id, wrk_id })}
        sx={{
          cursor: "pointer",
          "&:hover": {
            boxShadow: theme.shadows[12],
          },
          position: "relative",
        }}
      >
        <Stack spacing={1} p={2}>
          <CardHeader
            sx={{ p: 0 }}
            title={show_apv_name && <Typography variant="subtitle1">{apv_name}</Typography>}
            subheader={
              show_stages && (
                <Stack>
                  <Typography variant="body2" color="textSecondary">
                    Etapa: {currentStage?.stg_name}
                  </Typography>
                </Stack>
              )
            }
          />

          <IconButton
            onClick={handleMenuOpen}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 1,
            }}
          >
            <MoreVert />
          </IconButton>

          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={(event) => handleDelete(event)}>Excluir</MenuItem>
          </Menu>

          {show_stages && (
            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              <AvatarGroup max={50}>
                {allCustomers.map(({ customer }) => (
                  <RenderAvatar
                    key={`avatar-${apv_id}-${customer.cust_id}`}
                    src={customer.cust_photo?.ast_url}
                    variant="circular"
                    sx={{ width: 25, height: 25 }}
                    fallbackSize={15}
                  />
                ))}
              </AvatarGroup>
            </Stack>
          )}

          {show_workspace && (
            <Stack direction="row" spacing={1}>
              <RenderAvatar
                key={`workspace-${wrk_id}`}
                src={workspace.wrk_photo?.ast_url}
                variant="circular"
                sx={{ width: 25, height: 25 }}
                fallbackSize={25}
              />
              <Typography variant="body2" color="textSecondary" noWrap>
                {workspace.wrk_name}
              </Typography>
            </Stack>
          )}

          {show_apv_created_at && (
            <Typography variant="caption" color="textSecondary" mt={4}>
              {format(new Date(apv_created_at), "dd/MM/yyyy", { locale: ptBR })}
            </Typography>
          )}
        </Stack>
      </Card>
    );
  };

  const KanbanColumn: React.FC<{ title: string; cards: KanbanCardData[] }> = ({ title, cards }) => (
    <Stack
      bgcolor={theme.palette.divider}
      p={2}
      borderRadius={1}
      flexGrow={1} // Isso garante que todas as colunas tenham a mesma altura
    >
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={2}
      >
        <Typography variant="body1" fontWeight={"bold"} sx={{ mb: 2, textAlign: "center" }}>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          ({cards.length})
        </Typography>
      </Stack>
      <Stack spacing={2}>
        {cards.map((card) => (
          <KanbanCard key={`card-${card.apv_id}`} {...card} />
        ))}
      </Stack>
      {cards.length === 0 && (
        <Box textAlign="center">
          <Typography variant="body2" color="textSecondary">
            Nenhum item nesta coluna
          </Typography>
        </Box>
      )}
    </Stack>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="start"
      bgcolor={theme.palette.background.paper}
      alignItems="stretch"
    >
      {columns
        .sort((a, b) => a.stp_order - b.stp_order)
        .map((column) => (
          <Grid
            item
            xs={3}
            key={column.stp_id}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <KanbanColumn
              title={column.stp_label}
              cards={kanbanData.filter(
                (card) => card.apv_status.statusType.stp_type === column.stp_type,
              )}
            />
          </Grid>
        ))}
    </Grid>
  );
};
