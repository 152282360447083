import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Typography, Skeleton, Grid, Box, TextField } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import FormSection from "src/components/form/FormSection";
import { PageContainer } from "src/components/page-container";
import PhotoForm from "src/components/upload/photo-form";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { usePageView } from "src/hooks/use-page-view";
import { PersonalizationForm } from "src/sections/dashboard/personalization-form";
import { useThumbzApi } from "src/services/thumbz-api";
import { IOrganizationUpsertDto, IPersonalization } from "src/services/thumbz-base-api";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { queryKeys } from "src/config/query-key";

type IUpsertForm = IOrganizationUpsertDto;

export const Route = createFileRoute("/_authenticated/organizacao/detalhes/")({
  component: () => <Page />,
});

const Page = () => {
  const queryClient = useQueryClient();
  const selectedOrganization = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const orgPhoto = useAppSelector(organizationSlice.selectors.organizationPhoto);
  const { api } = useThumbzApi();

  const organization = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const dispatch = useAppDispatch();

  usePageView();

  function handleOnSuccessPersonalization(payload: IPersonalization) {
    dispatch(organizationSlice.actions.updateSelectedOrganizationPersonalization(payload));
  }

  const changeOrganizationMutationPhoto = useMutation({
    mutationFn: api.organization.organizationControllerUpdatePhoto,
    onSuccess(data) {
      dispatch(organizationSlice.actions.updateSelectedOrganization(data));
      toast.success("Avatar atualizado");
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  const updateOrganizationMutation = useMutation({
    mutationFn: api.organization.organizationControllerUpsert,
    onSuccess(data) {
      dispatch(organizationSlice.actions.updateSelectedOrganization(data));
      toast.success("Detalhes da organização atualizados com sucesso");

      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.some(
            (key) => typeof key === "string" && key.includes("organization"),
          );
        },
      });
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  const formik = useFormik<Partial<IUpsertForm>>({
    initialValues: {
      org_id: selectedOrganization?.org_id,
      org_name: selectedOrganization?.org_name,
    },
    validationSchema: Yup.object<IUpsertForm>({
      org_id: Yup.string().uuid("Formato de UUID inválido").optional(),
      org_name: Yup.string().required("Nome da organização é obrigatório"),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        await updateOrganizationMutation.mutateAsync({
          org_id: selectedOrganization?.org_id,
          org_name: String(values.org_name),
        });

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <PageContainer
      title={"Detalhes gerais da organização"}
      subtitle="Gerencie as configurações gerais da organização."
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <FormSection
              title="Detalhes do Organização"
              description={
                <>
                  Atualize os detalhes da sua organização
                  <br />
                  <br />
                  <strong>Nota:</strong> Essas informações serão usadas para exibir os detalhes do
                  organização no painel de controle.
                </>
              }
              actions={
                <LoadingButton
                  sx={{ m: 1 }}
                  type="submit"
                  loading={formik.isSubmitting}
                  variant="contained"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Salvar
                </LoadingButton>
              }
            >
              <FormikProvider value={formik}>
                <Typography color="text.secondary" component="p" sx={{ mb: 1 }} variant="overline">
                  Detalhes do Organização
                </Typography>
                <TextField
                  fullWidth
                  label="Nome do Organização"
                  name="org_name"
                  required
                  value={formik.values.org_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.org_name && Boolean(formik.errors.org_name)}
                  helperText={formik.touched.org_name && formik.errors.org_name}
                />
              </FormikProvider>
            </FormSection>
          </form>
        </Grid>

        {selectedOrganization?.org_id && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6">Avatar</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box>
                      <PhotoForm
                        key={`${orgPhoto}`}
                        default_photo_url={orgPhoto}
                        loading={changeOrganizationMutationPhoto.isPending}
                        onFormSuccessSubmit={function ({ base64 }): void {
                          changeOrganizationMutationPhoto.mutate({
                            base64: base64 || null,
                            org_id: selectedOrganization.org_id,
                          });
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          {organization ? (
            <PersonalizationForm
              personalization={organization.personalization}
              onSuccessCallback={handleOnSuccessPersonalization}
            />
          ) : (
            <Skeleton variant="rectangular" width="100%" height={400} />
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};
