import type { FC, ReactNode } from "react";

import { useRouter } from "src/hooks/use-router";
import { useBillingStatus } from "src/hooks/use-billing";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { StripePricingTable } from "src/components/stripe/strip-pricing-table";
import { Box, Container } from "@mui/system";
import { useAppSelector } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { TopNav } from "src/layouts/dashboard/marketing/top-nav";

interface BillingGuardProps {
  children: ReactNode;
}

export const BillingGuard: FC<BillingGuardProps> = (props) => {
  const selectedOrganization = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const shouldShowBilling = useAppSelector(organizationSlice.selectors.shouldShowBilling);
  const billingStatus = useBillingStatus();
  const { children } = props;
  const router = useRouter();

  if (shouldShowBilling) {
    return (
      <Box>
        <Box mb={"100px"}>
          <TopNav />
        </Box>
        <Container>
          <EmptyPlaceholder
            overlineText={`A organização "${selectedOrganization?.org_name}" não possui um plano ativo`}
            customSubtitle={"Você pode testar gratuitamente por 15 dias"}
            showButton={false}
            showElevation={true}
          >
            <StripePricingTable />
          </EmptyPlaceholder>
        </Container>
      </Box>
    );
  }

  return <>{children}</>;
};
