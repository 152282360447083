import type { FC } from "react";
import { useState } from "react";

import * as Yup from "yup";
import PropTypes from "prop-types";
import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useThumbzApi } from "src/services/thumbz-api";
import { useFormik } from "formik";
import { useMounted } from "src/hooks/use-mounted";
import { IWorkspaceUpsertForm } from "src/contexts/workspace/workspace-form";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { IPersonalizationForm } from "../personalization-form";
import { queryKeys } from "src/config/query-key";
import { Grid, Skeleton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { HexColorPicker } from "react-colorful";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { useAppSelector } from "src/app/hooks";
import { organizationSlice } from "src/features/organization/organizationSlice";
import { LoadingButton } from "@mui/lab";
import { useWorkspaceForm } from "src/hooks/use-workspace-form";

interface JobWorkspaceStepProps {
  onNext?: () => void;
  onBack?: () => void;
}

interface Values extends IPersonalizationForm {}

const getInitialValues = ({ personalization }: Partial<IWorkspaceUpsertForm>): Values => {
  return {
    per_id: String(personalization?.per_id),
    per_primary_hex_color: String(personalization?.per_primary_hex_color),
    per_text_hex_color: String(personalization?.per_text_hex_color),
    per_url: String(personalization?.per_url),
  };
};

const validationSchema = Yup.object<IPersonalizationForm>({
  per_id: Yup.string().nullable(),
  per_primary_hex_color: Yup.string()
    .nullable()
    .matches(/^#[0-9A-Fa-f]{6}$/),
  per_text_hex_color: Yup.string()
    .nullable()
    .matches(/^#[0-9A-Fa-f]{6}$/),
  per_url: Yup.string().nullable(),
});

export const WorkspaceUpsertStep3: FC<JobWorkspaceStepProps> = (props) => {
  const selectedOrganization = useAppSelector(organizationSlice.selectors.selectedOrganization);
  const router = useRouter();
  const queryClient = useQueryClient();
  const { api } = useThumbzApi();
  const [forceRender, setForceRender] = useState(false);
  const { state, dispatch } = useWorkspaceForm();

  const { onBack, onNext, ...other } = props;
  const isMounted = useMounted();

  const personalizationMutation = useMutation({
    mutationFn: api.personalization.personalizationControllerUpsert,
    onSuccess(payload) {
      toast.success("Workspace criado com sucesso!");

      if (isMounted()) {
        const wrk_id = state.formState.details.wrk_id;
        if (!wrk_id) {
          // router.push(paths.dashboard.index);
          router.navigate({
            from: "/workspaces/criar",
            to: "/",
            replace: true,
          });
          return;
        }

        queryClient.refetchQueries({
          queryKey: queryKeys.workspace.get({ wrk_id }).queryKey,
        });

        queryClient.invalidateQueries({
          predicate: (query) => {
            const queryKey = query.queryKey;
            return (
              queryKey.includes(
                queryKeys.workspace.list({ org_id: selectedOrganization.org_id }).queryKey,
              ) || queryKey.includes(queryKeys.workspace.get({ wrk_id }).queryKey)
            );
          },
        });

        router.navigate({
          from: "/workspaces/criar",
          to: "/workspaces/$wrk_id",
          replace: true,
          params: { wrk_id },
        });
      }
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  const formik = useFormik<IPersonalizationForm>({
    initialValues: getInitialValues(state.formState),
    validationSchema: validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      await personalizationMutation.mutateAsync({
        per_id: values.per_id,
        per_primary_hex_color: values.per_primary_hex_color ?? undefined,
        per_text_hex_color: values.per_text_hex_color ?? undefined,
      });
    },
  });

  function handleOnSuccessPersonalization() {
    setForceRender((prev) => !prev);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3} {...other}>
        <div>
          <Typography variant="h6">Personalização do workspace</Typography>
          <Typography variant="caption">Personalize as cores do seu workspace</Typography>
        </div>
        {state.formState.personalization ? (
          <Stack spacing={4} {...props}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Stack spacing={3}>
                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <TextField
                      error={
                        !!(
                          formik.touched.per_primary_hex_color &&
                          formik.errors.per_primary_hex_color
                        )
                      }
                      fullWidth
                      helperText={
                        formik.touched.per_primary_hex_color && formik.errors.per_primary_hex_color
                      }
                      label="Cor primária"
                      name="per_primary_hex_color"
                      disabled={formik.isSubmitting}
                      onBlur={formik.handleBlur}
                      defaultValue={formik.values.per_primary_hex_color}
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.per_primary_hex_color || "#FFFFFF"}
                      sx={{ flexGrow: 1 }}
                    />
                    <HexColorPicker
                      color={formik.values.per_primary_hex_color || "#FFFFFF"}
                      onChange={(color) => {
                        formik.setFieldValue("per_primary_hex_color", color);
                      }}
                    />
                  </Stack>

                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <TextField
                      error={
                        !!(formik.touched.per_text_hex_color && formik.errors.per_text_hex_color)
                      }
                      fullWidth
                      helperText={
                        formik.touched.per_text_hex_color && formik.errors.per_text_hex_color
                      }
                      label="Cor do texto"
                      name="per_text_hex_color"
                      disabled={formik.isSubmitting}
                      defaultValue={formik.values.per_text_hex_color}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.per_text_hex_color || "#000000"}
                      sx={{ flexGrow: 1 }}
                    />
                    <HexColorPicker
                      color={formik.values.per_text_hex_color || "#000000"}
                      onChange={(color) => {
                        formik.setFieldValue("per_text_hex_color", color);
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <Box>
            <Skeleton height={100} />
          </Box>
        )}

        <Stack alignItems="center" direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            loading={formik.isSubmitting || personalizationMutation.isPending}
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            variant="contained"
          >
            Finalizar
          </LoadingButton>
          <Button color="inherit" onClick={onBack}>
            Voltar
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

WorkspaceUpsertStep3.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
