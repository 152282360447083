import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Stack,
  Grid,
  TextField,
  Chip,
  Tooltip,
  Divider,
  Typography,
} from "@mui/material";
import Slider, { Settings } from "react-slick";
import {
  approvalCreateSlice,
  deleteApprovalItem,
  ISliceItemUpsertDto,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { Mimetype } from "src/utils/get-mimetype";
import { useAppDispatch } from "src/app/hooks";
import toast from "react-hot-toast";
import {
  IApprovalStageCustomer,
  IStatus,
  IStatusTypeStpTypeEnum,
} from "src/services/thumbz-base-api";
import { getApprovalStatusChips } from "src/utils/status";
import { useConfirm } from "material-ui-confirm";
import { InstagramCarouselEdit } from "./instagram-carousel-edit";
import { InstagramRenderImgPreview } from "./instagram-render-img-preview";
import { ArrowRight } from "@untitled-ui/icons-react";
import { RichTextEditor } from "./form/RichTextEditor";

interface InstagramCarouselCardProps {
  approvalItem: ISliceItemUpsertDto;
  onUnmergeAll: () => void;
  onUnmergeSingle: (mediaIndex: number) => void;
  enableThumbnail?: boolean;
  approvalStageCustomers: IApprovalStageCustomer[];
  apv_status: IStatus;
}

export const InstagramCarouselApprovalItemCard: React.FC<InstagramCarouselCardProps> = ({
  approvalItem,
  onUnmergeAll,
  onUnmergeSingle,
  approvalStageCustomers,
  enableThumbnail = false,
  apv_status,
}) => {
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  const [isReorderModalOpen, setReorderModalOpen] = useState<boolean>(false);

  function handleSelectToEdit(): void {
    dispatch(approvalCreateSlice.actions.selectApprovalItem(approvalItem));
  }

  const handleOpenReorderModal = () => {
    setReorderModalOpen(true);
  };

  const handleCloseReorderModal = () => {
    setReorderModalOpen(false);
  };

  const statusChips = getApprovalStatusChips(approvalStageCustomers);

  function handleDeleteApprovalItem(approvalItem: ISliceItemUpsertDto): void {
    confirm({
      description: "Tem certeza que deseja remover este item?",
      confirmationText: "Sim",
      cancellationText: "Não",
    }).then(() => {
      dispatch(
        deleteApprovalItem({
          item_id: approvalItem.item_id,
        }),
      );
    });
  }

  const fileName =
    approvalItem?.assets[0].file?.name ||
    approvalItem?.assets[0].uploaded_asset?.ast_original_filename;

  return (
    <Card sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
      <Grid container>
        <Grid item xs={4} mb={approvalItem.assets.length > 1 ? 4 : 0}>
          {approvalItem.assets.length > 1 ? (
            <Slider
              {...{
                dots: true,
                centerMode: false,
                arrows: true,
                infinite: false,
                nextArrow: <Box sx={{ zIndex: 10, width: 40, height: 40, right: "-20px" }} />,
                prevArrow: <Box sx={{ zIndex: 10, width: 40, height: 40, left: "0px" }} />,
                dotsClass: "slick-dots slick-thumb",
              }}
            >
              {approvalItem.assets.map((item, index) => {
                const fileName = item.file?.name || item.uploaded_asset?.ast_original_filename;
                return (
                  <div key={`media-${index}`}>
                    <InstagramRenderImgPreview
                      file={item.file}
                      mimetype={item.props?.mimetype ?? Mimetype.Image}
                      already_uploaded_url={item.already_uploaded_url}
                      showThumbnail={enableThumbnail}
                    />
                    <Stack>
                      {fileName && (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          align="center"
                          sx={{ fontSize: "0.70rem" }}
                        >
                          {fileName}
                        </Typography>
                      )}
                    </Stack>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <>
              <Stack flex={1} justifyContent="center" alignItems="center">
                {approvalItem.assets.length > 0 && (
                  <InstagramRenderImgPreview
                    file={approvalItem.assets[0].file}
                    mimetype={approvalItem.assets[0].props?.mimetype ?? Mimetype.Image}
                    already_uploaded_url={approvalItem.assets[0].already_uploaded_url}
                    showThumbnail={enableThumbnail}
                  />
                )}
                {fileName && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    align="center"
                    sx={{ fontSize: "0.70rem" }}
                  >
                    {fileName}
                  </Typography>
                )}
              </Stack>
            </>
          )}
        </Grid>
        <Grid item xs={8}>
          <CardContent>
            <RichTextEditor
              value={approvalItem.item_description ?? ""}
              onChange={function (content: string): void {
                if (approvalItem.item_id) {
                  dispatch(
                    approvalCreateSlice.actions.changeDescription({
                      item_id: approvalItem.item_id,
                      description: content,
                    }),
                  );
                } else {
                  toast.error("Não foi possível alterar a descrição");
                }
              }}
            />
            {apv_status.statusType.stp_type !== IStatusTypeStpTypeEnum.NOT_STARTED && (
              <Stack>
                <Box sx={{ mt: 2 }}>
                  {statusChips.map((chip) => (
                    <Tooltip key={chip.label} title={chip.tooltip} arrow>
                      <Chip
                        style={{ cursor: "pointer" }}
                        key={chip.label}
                        label={chip.label}
                        variant="outlined"
                        color={chip.color}
                        icon={chip.icon}
                        sx={{ mr: 1, mb: 1 }}
                      />
                    </Tooltip>
                  ))}
                </Box>
              </Stack>
            )}
          </CardContent>
          <CardActions>
            <Button size="small" onClick={handleSelectToEdit}>
              Editar
            </Button>
            <Button size="small" onClick={() => handleDeleteApprovalItem(approvalItem)}>
              Remover
            </Button>
          </CardActions>
        </Grid>
      </Grid>
      <InstagramCarouselEdit />
    </Card>
  );
};
