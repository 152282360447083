import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { IApproval, IStatusTypeStpTypeEnum } from "src/services/thumbz-base-api";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  approvalCreateSlice,
  goToStep1,
  goToStep2,
  goToStep4,
} from "src/features/approvalsCreate/approvalCreateSlice";
import { StatusTitle } from "src/components/status/StatusTitle";
import { StatusIcon } from "src/components/status/StatusIcon";
import { InstagramRenderImgPreview } from "src/components/instagram-render-img-preview";
import { Mimetype } from "src/utils/get-mimetype";
import Slider from "react-slick";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { useConfirm } from "material-ui-confirm";
import { EmailOutlined, WhatsApp } from "@mui/icons-material";
import { useMounted } from "src/hooks/use-mounted";
import { LoadingButton } from "@mui/lab";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { useRouter } from "src/hooks/use-router";
import { RichTextRenderer } from "src/components/richtext/RichTextRender";

interface IApprovalRevisionProps {
  approval: IApproval;
}

export const ApprovalRevision: React.FC<IApprovalRevisionProps> = (props) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const isLoading = useAppSelector((state) => state.approvalCreate.status === "loading");
  const isMounted = useMounted();
  const confirm = useConfirm();
  const { api } = useThumbzApi();
  const dispatch = useAppDispatch();
  const approvalItems = useSelector(approvalCreateSlice.selectors.selectApprovalItems);

  const statusParams = { wrk_id: props.approval?.workspace.wrk_id };
  const statusQuery = useQuery({
    queryKey: queryKeys.status.statusControllerFindByWorkspace(statusParams).queryKey,
    queryFn: () => api.status.statusControllerFindByWorkspace(statusParams.wrk_id),
  });

  const approvalQuery = useQuery({
    queryKey: queryKeys.approval?.approvalControllerGet(props.approval?.apv_id).queryKey,
    queryFn: () => api.approval?.approvalControllerGet(props.approval?.apv_id),
    enabled: !!props.approval?.apv_id && isMounted(),
  });

  const approval = approvalQuery.data;

  function onStartApproval() {
    confirm({
      title: "Deseja iniciar a aprovação?",
      description:
        "Ao iniciar a aprovação, os aprovadores receberão a notificação para aprovar e você não poderá mais editar a aprovação.",
      confirmationText: "Sim",
      cancellationText: "Não",
    }).then(() => {
      handleChangeStatus();
    });
  }

  const changeStatusMutation = useMutation({
    mutationFn: api.approval.approvalControllerChangeStatus,
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
    onSuccess: (response) => {
      const { apv_id, workspace } = response;
      const queryKey = queryKeys.approval.approvalControllerGet(apv_id).queryKey;
      queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes("approval") });
      queryClient.setQueryData(queryKey, response);
      dispatch(approvalCreateSlice.actions.reset());

      return router.navigate({
        to: "/workspaces/$wrk_id/aprovacao/$apv_id/detalhes",
        params: { wrk_id: workspace.wrk_id, apv_id },
        replace: true,
      });
    },
  });

  const allStatus = statusQuery.data ?? [];
  function handleChangeStatus() {
    const inReview = allStatus.find(
      ({ statusType }) => statusType.stp_type === IStatusTypeStpTypeEnum.IN_REVIEW,
    );
    if (!inReview) {
      toast.error(`Não foi possível trocar o status da aprovação ${approval?.apv_name}`);
      return;
    }

    changeStatusMutation.mutate({
      apv_id: approval?.apv_id,
      sts_id: inReview.sts_id,
    });
  }

  function _goToStep1() {
    dispatch(approvalCreateSlice.actions.changeStep(0));
  }

  function _goToStep2() {
    dispatch(approvalCreateSlice.actions.changeStep(1));
  }

  return (
    <Card>
      <CardHeader
        title="Revisão da Aprovação"
        subheader="Verifique as informações da aprovação antes de enviar para os aprovadores."
      />
      <CardContent>
        <Stack spacing={3}>
          {/* Exibição resumida das informações principais */}
          <Stack>
            <StatusTitle title="Nome da Aprovação" />
            <Typography variant="body1">{approval?.apv_name}</Typography>
          </Stack>

          <Stack>
            <StatusTitle title="Status" />
            <Stack direction={"row"} alignItems={"center"} spacing={1} flex={1}>
              {approval?.apv_status.statusType.stp_type && (
                <StatusIcon
                  statusType={approval?.apv_status.statusType.stp_type}
                  color={"#878787"}
                />
              )}
              <Typography variant="body1">{approval?.apv_status.sts_name}</Typography>
            </Stack>
          </Stack>

          <Stack flex={1} flexGrow={1}>
            <StatusTitle title="Itens para Aprovar" />
            {approvalItems.length > 0 ? (
              <Grid container spacing={1}>
                {approvalItems?.map((item, index) => (
                  <Grid item key={item.item_id} xs={4}>
                    {item.assets.length > 1 ? (
                      <>
                        <Slider
                          {...{
                            dots: true,
                            centerMode: false,
                            arrows: true,
                            infinite: false,
                            nextArrow: (
                              <Box sx={{ zIndex: 10, width: 40, height: 40, right: "-20px" }} />
                            ),
                            prevArrow: (
                              <Box sx={{ zIndex: 10, width: 40, height: 40, left: "0px" }} />
                            ),
                            dotsClass: "slick-dots slick-thumb",
                          }}
                        >
                          {item.assets.map((_item, _index) => (
                            <div key={`media-${_index}`}>
                              <InstagramRenderImgPreview
                                file={_item.file}
                                mimetype={_item.props?.mimetype ?? Mimetype.Image}
                                already_uploaded_url={_item.already_uploaded_url}
                                showThumbnail={true}
                              />
                              <Stack>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  align="center"
                                  sx={{ fontSize: "0.70rem" }}
                                >
                                  Arquivo {_index + 1}:{" "}
                                  {_item.file?.name ?? _item.uploaded_asset?.ast_original_filename}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  align="center"
                                  sx={{ fontSize: "0.70rem" }}
                                >
                                  Item {index + 1} - Legenda:{" "}
                                </Typography>
                                <RichTextRenderer
                                  content={item.item_description ?? "Nenhuma legenda informada"}
                                />
                              </Stack>
                            </div>
                          ))}
                        </Slider>
                      </>
                    ) : (
                      <>
                        {item.assets.length > 0 && (
                          <Stack>
                            <InstagramRenderImgPreview
                              file={item.assets[0].file}
                              mimetype={item.assets[0].props?.mimetype ?? Mimetype.Image}
                              already_uploaded_url={item.assets[0].already_uploaded_url}
                            />
                            <Grid item xs={12}>
                              <Stack>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  align="center"
                                  sx={{ fontSize: "0.70rem" }}
                                >
                                  Arquivo:{" "}
                                  {item.assets[0].file?.name ??
                                    item.assets[0].uploaded_asset?.ast_original_filename}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  align="center"
                                  sx={{ fontSize: "0.70rem" }}
                                >
                                  Item {index + 1} - Legenda:{" "}
                                </Typography>

                                <RichTextRenderer
                                  content={item.item_description ?? "Nenhuma legenda informada"}
                                />
                              </Stack>
                            </Grid>
                          </Stack>
                        )}
                      </>
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body2">Nenhum item adicionado.</Typography>
            )}
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <Button variant="text" color="primary" onClick={_goToStep1}>
                Editar
              </Button>
            </Stack>
          </Stack>

          <Divider />

          <Stack flex={1} flexGrow={1}>
            <StatusTitle title="Etapas e Aprovadores" />
            <Stack direction="row" spacing={2}>
              {approval?.stages.map(({ approvalStageCustomers, stg_name, stg_id }) => (
                <Box
                  key={stg_id}
                  flexGrow={1}
                  p={1}
                  border={1}
                  borderColor="grey.500"
                  borderRadius={1}
                >
                  <Typography variant="body1" gutterBottom>
                    {stg_name}
                  </Typography>
                  <Stack spacing={1}>
                    {approvalStageCustomers.map(({ customer }) => (
                      <Stack key={customer.cust_id} direction="row" alignItems="center" spacing={1}>
                        <Chip
                          avatar={
                            <Avatar src={customer.cust_photo?.ast_url ?? undefined}>
                              {customer.cust_name.charAt(0)}
                            </Avatar>
                          }
                          label={customer.cust_name}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="flex-end">
            <Button variant="text" color="primary" onClick={_goToStep2}>
              Editar
            </Button>
          </Stack>

          <Divider />

          <Stack flex={1} flexGrow={1}>
            <StatusTitle title="Notificações" />
            <List>
              <ListItem>
                <ListItemIcon>
                  <WhatsApp />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-wifi"
                  primary="WhatsApp"
                  secondary="Todos os aprovadores receberão uma mensagem no WhatsApp"
                />
                <Switch edge="end" checked={true} readOnly disabled />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmailOutlined />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-bluetooth"
                  primary="E-mail"
                  secondary="Todos os aprovadores receberão um e-mail"
                />
                <Switch edge="end" checked={true} readOnly disabled />
              </ListItem>
            </List>
          </Stack>

          <CardActions>
            <Stack direction="row" justifyContent="flex-end" flex={1} flexGrow={1}>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={onStartApproval}
                loading={isLoading || changeStatusMutation.isPending}
              >
                Iniciar Aprovação
              </LoadingButton>
            </Stack>
          </CardActions>
        </Stack>
      </CardContent>
    </Card>
  );
};
