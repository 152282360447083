import { createFileRoute } from "@tanstack/react-router";

import type { ChangeEvent } from "react";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "src/config/query-key";
import { useThumbzApi } from "src/services/thumbz-api";
import { useRouter } from "src/hooks/use-router";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { EmptyPlaceholder } from "src/sections/components/EmptyPlaceholder";
import { SplashScreen } from "src/components/splash-screen";
import { IApprovalFlowStage } from "src/services/thumbz-base-api";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import { Add, Edit, Delete, Reorder, ArrowBackIos } from "@mui/icons-material";
import { ApprovalFlowCustomersTable } from "src/sections/approval-flow/approval-flow-stage-customers-table";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import toast from "react-hot-toast";
import { useConfirm } from "material-ui-confirm";
import { ApprovalFlowUpsertForm } from "src/sections/approval-flow/approval-flow-stage-upsert-form";
import { ApprovalFlowReorderForm } from "src/sections/approval-flow/approval-flow-stage-reorder-form";
import { PageContainer } from "src/components/page-container";

export const Route = createFileRoute("/_authenticated/workspaces/$wrk_id/flow/$flow_id/")({
  component: () => <Page />,
});

const Page = () => {
  usePageView();
  const [openReorder, setOpenReorder] = useState(false);
  const { flow_id, wrk_id } = Route.useParams();
  const router = useRouter();
  const { api } = useThumbzApi();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const [currentTab, setCurrentTab] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogStage, setDialogStage] = useState<Partial<IApprovalFlowStage>>({});

  const approvalFlowQuery = useQuery({
    queryKey: queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
    queryFn: () => api.approvalFlow.approvalFlowControllerFind(flow_id),
    enabled: !!flow_id,
  });

  useEffect(() => {
    // Verifica se os dados foram carregados e se há pelo menos uma etapa
    if (
      approvalFlowQuery.isSuccess &&
      approvalFlowQuery.data?.stages.length > 0 &&
      currentTab === null
    ) {
      // Define o currentTab para o valor da primeira aba, se currentTab ainda não estiver definido
      setCurrentTab(`stage-${approvalFlowQuery.data.stages[0].afs_id}`);
    }
  }, [approvalFlowQuery.isSuccess, approvalFlowQuery.data, currentTab]);

  const createStageMutation = useMutation({
    mutationFn: api.approvalFlowStage.approvalFlowStageControllerUpsert,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
      });
      toast.success("Etapa criada com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const updateStageMutation = useMutation({
    mutationFn: api.approvalFlowStage.approvalFlowStageControllerUpsert,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
      });
      toast.success("Etapa atualizada com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const deleteStageMutation = useMutation({
    mutationFn: api.approvalFlowStage.approvalFlowStageControllerDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.approvalFlow.approvalFlowControllerFind({ flow_id }).queryKey,
      });
      toast.success("Etapa deletada com sucesso");
    },
    onError: (error) =>
      getServerErrorArrayMessage(error).forEach((message) => toast.error(message)),
  });

  const handleTabsChange = useCallback((event: ChangeEvent<any>, value: string): void => {
    setCurrentTab(value);
  }, []);

  if (!flow_id) {
    return <EmptyPlaceholder overlineText="Nenhum fluxo selecionado" />;
  }

  if (approvalFlowQuery.isLoading) {
    return <SplashScreen />;
  }

  if (approvalFlowQuery.isError) {
    return <EmptyPlaceholder overlineText="Erro ao carregar o fluxo" />;
  }

  const approvalFlow = approvalFlowQuery.data;

  if (!approvalFlow) {
    return <EmptyPlaceholder overlineText="Fluxo não encontrado" />;
  }

  const stageTabs = approvalFlow.stages.map((stage: IApprovalFlowStage, index: number) => ({
    label: `Etapa ${index + 1} - ${stage.afs_name}`,
    value: `stage-${stage.afs_id}`,
  }));

  const handleEditStage = (stage: IApprovalFlowStage) => {
    setDialogStage(stage);
    setIsDialogOpen(true);
  };

  const handleDeleteStage = (afs_id: string) => {
    confirm({
      description: "Tem certeza que deseja excluir esta etapa?",
      confirmationText: "Sim, excluir",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(() => {
      deleteStageMutation.mutate({
        afs_id,
      });
    });
  };

  function handleAddStage() {
    setDialogStage({});
    setIsDialogOpen(true);
  }

  function handleReorderStages() {
    setOpenReorder(true);
  }

  function handleChangeCurrentTabAfterReorder(stages: IApprovalFlowStage[]) {
    setCurrentTab(`stage-${stages[0].afs_id}`);
  }

  function handleDeleteFlow() {
    confirm({
      description: "Tem certeza que deseja excluir este fluxo?",
      confirmationText: "Sim, excluir",
      confirmationButtonProps: {
        color: "error",
      },
    }).then(() => {
      api.approvalFlow.approvalFlowControllerDelete({ flow_id }).then(() => {
        // TODO:
        queryClient.invalidateQueries({
          queryKey: queryKeys.approvalFlow.approvalFlowControllerList({ wrk_id: "" }).queryKey,
        });
        toast.success("Fluxo deletado com sucesso");
        router.navigate({
          from: "/workspaces/$wrk_id/flow/$flow_id",
          to: "/workspaces",
        });
      });
    });
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
    setDialogStage({});
  }

  const handleDialogSave = (stage: Partial<IApprovalFlowStage>) => {
    if (stage.afs_id) {
      updateStageMutation.mutate(stage as IApprovalFlowStage);
    } else {
      createStageMutation.mutate({
        ...stage,
        approvalFlow: { flow_id },
      } as IApprovalFlowStage);
    }
    handleDialogClose();
  };

  return (
    <>
      <PageContainer
        title={`Template: ${approvalFlow.flow_name}`}
        breadcrumbs={[
          {
            label: "Workspaces",
            href: "/workspaces",
          },
          {
            label: "Fluxos",
            href: "/workspaces/$wrk_id",
          },
          {
            label: approvalFlow.flow_name,
            href: `/workspaces/$wrk_id/flow/$flow_id`,
          },
        ]}
        subtitle={
          approvalFlow.flow_description || "Crie fluxos que serão reutilizados em suas aprovações"
        }
        leftNode={
          <Stack justifyContent={"center"}>
            <ButtonGroup variant="outlined" color="primary" size="small">
              <Button onClick={handleAddStage}>
                <Add />
              </Button>
              <Button onClick={handleReorderStages} disabled={approvalFlow.stages.length <= 1}>
                <Reorder />
              </Button>
              <Button onClick={handleDeleteFlow} color="error">
                <Delete />
              </Button>
            </ButtonGroup>
          </Stack>
        }
      >
        <Stack spacing={4}>
          <Card>
            {approvalFlow.stages.length === 0 ? (
              <EmptyPlaceholder
                overlineText="Nenhuma etapa encontrada"
                customSubtitle="Utilize o botão + acima para adicionar uma nova etapa"
                showButton={false}
              />
            ) : (
              <>
                <Divider />
                <Stack direction="row" spacing={2} px={2}>
                  <Box flexGrow={1}>
                    <Tabs
                      indicatorColor="primary"
                      onChange={handleTabsChange}
                      scrollButtons="auto"
                      textColor="primary"
                      value={currentTab || stageTabs[0]?.value}
                      variant="scrollable"
                    >
                      {stageTabs.map((tab) => (
                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                      ))}
                    </Tabs>
                  </Box>
                </Stack>
              </>
            )}
            {approvalFlow.stages.map((stage: IApprovalFlowStage) => (
              <div key={stage.afs_id}>
                {currentTab === `stage-${stage.afs_id}` && (
                  <>
                    <Box display="flex" justifyContent="space-between" p={2}>
                      <Typography variant="h6">{stage.afs_name}</Typography>
                      <Box>
                        <IconButton onClick={() => handleEditStage(stage)} color="primary">
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteStage(stage.afs_id)} color="error">
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                    <ApprovalFlowCustomersTable stage={stage} wrk_id={wrk_id} />
                  </>
                )}
              </div>
            ))}
          </Card>
        </Stack>
      </PageContainer>
      <ApprovalFlowUpsertForm
        open={isDialogOpen}
        stage={dialogStage}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
      />
      <ApprovalFlowReorderForm
        open={openReorder}
        stages={approvalFlow.stages}
        flow_id={flow_id}
        onClose={() => setOpenReorder(false)}
        onSuccess={() => {}}
      />
    </>
  );
};

export default Page;
