import React, { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { createClient } from "./utils/supabase/client";

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <h1>404 Not Found</h1>,
  context: {
    auth: undefined!,
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

import { LinkProps as TanstackLinkProps } from "@tanstack/react-router";
export type RouterPaths = TanstackLinkProps["to"];

export function InnerApp() {
  const { auth } = createClient();

  useEffect(() => {
    const {
      data: { subscription },
    } = auth.onAuthStateChange((event) => {
      if (event === "SIGNED_IN" || event === "SIGNED_OUT" || event === "PASSWORD_RECOVERY") {
        router.invalidate();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [auth]);

  return <RouterProvider router={router} context={{ auth }} />;
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <InnerApp />
    </StrictMode>,
  );
}
