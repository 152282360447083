import { type FC } from "react";
import * as Yup from "yup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useThumbzApi } from "src/services/thumbz-api";
import { IPersonalization } from "src/services/thumbz-base-api";
import { HexColorPicker } from "react-colorful";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getServerErrorArrayMessage } from "src/utils/get-server-error-message";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface IPersonalizationFormProps {
  personalization: IPersonalizationForm;
  onSuccessCallback?: (payload: IPersonalization) => void;
}

export type IPersonalizationForm = Omit<
  IPersonalization,
  "per_created_at" | "per_updated_at" | "per_deleted_at"
>;

const validationSchema = Yup.object<IPersonalizationForm>({
  per_id: Yup.string().nullable(),
  per_photo_url: Yup.string().nullable(),
  per_primary_hex_color: Yup.string()
    .nullable()
    .matches(/^#[0-9A-Fa-f]{6}$/),
  per_text_hex_color: Yup.string()
    .nullable()
    .matches(/^#[0-9A-Fa-f]{6}$/),
  per_url: Yup.string().nullable(),
});

export const PersonalizationForm: FC<IPersonalizationFormProps> = (props) => {
  const { api } = useThumbzApi();

  const personalizationMutation = useMutation({
    mutationFn: api.personalization.personalizationControllerUpsert,
    onSuccess(payload) {
      toast.success("Atualizado com sucesso!");
      if (props.onSuccessCallback) {
        props.onSuccessCallback(payload);
      }
    },
    onError(error) {
      const errors = getServerErrorArrayMessage(error);
      errors.forEach((error) => toast.error(error));
    },
  });

  const formik = useFormik<IPersonalizationForm>({
    initialValues: {
      per_id: props.personalization.per_id,
      per_primary_hex_color: props.personalization.per_primary_hex_color,
      per_text_hex_color: props.personalization.per_text_hex_color,
      per_url: props.personalization.per_url,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      await personalizationMutation.mutateAsync({
        per_id: values.per_id,
        per_primary_hex_color: values.per_primary_hex_color ?? undefined,
        per_text_hex_color: values.per_text_hex_color ?? undefined,
      });
    },
  });

  return (
    <Stack spacing={4} {...props}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Personalização</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack spacing={3}>
                  <Stack alignItems="flex-start" direction="row" spacing={2}>
                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                      <TextField
                        error={
                          !!(
                            formik.touched.per_primary_hex_color &&
                            formik.errors.per_primary_hex_color
                          )
                        }
                        fullWidth
                        helperText={
                          formik.touched.per_primary_hex_color &&
                          formik.errors.per_primary_hex_color
                        }
                        label="Cor primária"
                        name="per_primary_hex_color"
                        disabled={formik.isSubmitting}
                        onBlur={formik.handleBlur}
                        defaultValue={formik.values.per_primary_hex_color}
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.per_primary_hex_color || "#FFFFFF"}
                        sx={{ flexGrow: 1 }}
                      />
                      <HexColorPicker
                        color={formik.values.per_primary_hex_color || "#FFFFFF"}
                        onChange={(color) => {
                          formik.setFieldValue("per_primary_hex_color", color);
                        }}
                      />
                    </Stack>

                    <LoadingButton
                      loading={formik.isSubmitting}
                      type="submit"
                      color="inherit"
                      size="small"
                    >
                      Salvar
                    </LoadingButton>
                  </Stack>

                  <Stack alignItems="flex-start" direction="row" spacing={2}>
                    <Stack spacing={2} sx={{ flexGrow: 1 }}>
                      <TextField
                        error={
                          !!(formik.touched.per_text_hex_color && formik.errors.per_text_hex_color)
                        }
                        fullWidth
                        helperText={
                          formik.touched.per_text_hex_color && formik.errors.per_text_hex_color
                        }
                        label="Cor do texto"
                        name="per_text_hex_color"
                        disabled={formik.isSubmitting}
                        defaultValue={formik.values.per_text_hex_color}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        variant="outlined"
                        value={formik.values.per_text_hex_color || "#000000"}
                        sx={{ flexGrow: 1 }}
                      />
                      <HexColorPicker
                        color={formik.values.per_text_hex_color || "#000000"}
                        onChange={(color) => {
                          formik.setFieldValue("per_text_hex_color", color);
                        }}
                      />
                    </Stack>

                    <LoadingButton
                      loading={formik.isSubmitting}
                      type="submit"
                      color="inherit"
                      size="small"
                    >
                      Salvar
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Stack>
  );
};
