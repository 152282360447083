import { Link, Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { SettingsConsumer } from "../contexts/settings";
import { SupabaseAuthClient } from "@supabase/supabase-js/dist/module/lib/SupabaseAuthClient";
import { Provider } from "react-redux";
import { store, persistor } from "../app/store";
import { OrganizationProvider } from "../contexts/organization/OrganizationContext";
import { SplashScreen } from "../components/splash-screen";
import { PersistGate } from "redux-persist/integration/react";
import { AuthContext } from "src/contexts/auth.context";
import { QueryClientWrapper } from "src/contexts/QueryClientWrapper";
import { SettingsContextProvider } from "src/contexts/SettingsContextProvider";
import { ThemeWrapper } from "src/contexts/ThemeWrapper";
import { ComponentWrapper } from "src/contexts/ComponentLayout";

interface MyRouterContext {
  auth: SupabaseAuthClient;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootComponent,
  notFoundComponent: () => {
    return (
      <div>
        <p>This is the notFoundComponent configured on root route</p>
        <Link to="/">Start Over</Link>
      </div>
    );
  },
});

function RootComponent() {
  return (
    <Provider store={store}>
      <PersistGate loading={<SplashScreen />} persistor={persistor}>
        <QueryClientWrapper>
          <AuthContext>
            <OrganizationProvider>
              <SettingsContextProvider>
                <SettingsConsumer>
                  {(settings) => (
                    <ThemeWrapper settings={settings}>
                      <ComponentWrapper settings={settings}>
                        <Outlet />
                      </ComponentWrapper>
                    </ThemeWrapper>
                  )}
                </SettingsConsumer>
              </SettingsContextProvider>
            </OrganizationProvider>
          </AuthContext>
        </QueryClientWrapper>
      </PersistGate>
    </Provider>
  );
}
