import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/app/hooks'
import { PageContainer } from 'src/components/page-container'
import { queryKeys } from 'src/config/query-key'
import { organizationSlice } from 'src/features/organization/organizationSlice'
import { usePageView } from 'src/hooks/use-page-view'
import { useWorkspace } from 'src/hooks/use-workspace'
import { useWorkspaces } from 'src/hooks/use-workspaces'
import { WorkspaceDashboard } from 'src/sections/dashboard/workspace/workspace-dashboard'
import { useThumbzApi } from 'src/services/thumbz-api'
import { IPersonalization } from 'src/services/thumbz-base-api'
import { getServerErrorArrayMessage } from 'src/utils/get-server-error-message'

export const Route = createFileRoute('/_authenticated/dashboard')({
  component: () => <Page />,
})

const Page = () => {
  const queryClient = useQueryClient()
  const { data } = useWorkspaces()
  const selectedOrganization = useSelector(
    organizationSlice.selectors.selectedOrganization,
  )
  const orgPhoto = useSelector(organizationSlice.selectors.organizationPhoto)
  const { api } = useThumbzApi()

  const [currentTab, setCurrentTab] = useState<string>('general')
  const organization = useSelector(
    organizationSlice.selectors.selectedOrganization,
  )
  const dispatch = useAppDispatch()

  usePageView()

  function handleOnSuccessPersonalization(payload: IPersonalization) {
    dispatch(
      organizationSlice.actions.updateSelectedOrganizationPersonalization(
        payload,
      ),
    )
  }

  const changeOrganizationMutationPhoto = useMutation({
    mutationFn: api.organization.organizationControllerUpdatePhoto,
    onSuccess(data, variables, context) {
      dispatch(organizationSlice.actions.updateSelectedOrganization(data))
      toast.success('Avatar atualizado')
    },
    onError(error, variables, context) {
      const errors = getServerErrorArrayMessage(error)
      errors.forEach((error) => toast.error(error))
    },
  })

  useEffect(() => {
    if (data) {
      for (const { wrk_id } of data) {
        queryClient.prefetchQuery({
          queryKey: queryKeys.workspace.get({ wrk_id }).queryKey,
          queryFn: () => api.workspace.workspaceControllerGet(wrk_id),
        })
      }
    }
  }, [api.workspace, data, queryClient])

  return (
    <PageContainer
      title={'Dashboard da organização'}
      subtitle="Acompanhe o desempenho da organização e seus workspaces."
    >
      <WorkspaceDashboard wrk_id={undefined} />
    </PageContainer>
  )
}
