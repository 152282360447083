import { useEffect, useRef } from "react";
import {
  MenuButtonOrderedList,
  RichTextEditor as MuiRichTextEditor,
  type RichTextEditorRef,
} from "mui-tiptap";
import StarterKit from "@tiptap/starter-kit";
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuButtonBulletedList,
} from "mui-tiptap";

interface RichTextEditorProps {
  value: string | undefined;
  onChange: (content: string) => void;
}

export function RichTextEditor({ value, onChange }: RichTextEditorProps) {
  const rteRef = useRef<RichTextEditorRef>(null);

  useEffect(() => {
    if (!rteRef.current) return;

    const { editor } = rteRef.current;
    if (!editor) return;

    editor.on("update", ({ editor }) => {
      onChange(editor.getHTML());
    });

    return () => {
      editor.off("update");
    };
  }, [onChange]);

  return (
    <div>
      <MuiRichTextEditor
        ref={rteRef}
        extensions={[StarterKit]}
        content={value}
        renderControls={() => (
          <MenuControlsContainer>
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuDivider />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
          </MenuControlsContainer>
        )}
      />
    </div>
  );
}
