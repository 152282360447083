import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CheckIcon from "@untitled-ui/icons-react/build/esm/Check";
import Avatar from "@mui/material/Avatar";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import type { StepIconProps } from "@mui/material/StepIcon";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import { WorkspaceUpsertStep2Photo } from "./workspace-upsert-step-2-photo";
import { WorkspaceUpsertStep3 } from "./workspace-upsert-step-3-personalization";
import { WorkspaceUpsertStep1Details } from "./workspace-upsert-step-1-details";
import { JobPreview } from "./job-preview";
import { useWorkspaceForm } from "src/hooks/use-workspace-form";

const StepIcon: FC<StepIconProps> = (props) => {
  const { active, completed, icon } = props;

  const highlight = active || completed;

  return (
    <Avatar
      sx={{
        height: 40,
        width: 40,
        ...(highlight && {
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        }),
      }}
      variant="rounded"
    >
      {completed ? (
        <SvgIcon>
          <CheckIcon />
        </SvgIcon>
      ) : (
        icon
      )}
    </Avatar>
  );
};

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node.isRequired,
};

export const WorkspaceUpsertForm: FC = () => {
  const { state, dispatch } = useWorkspaceForm();

  const handleNext = useCallback(() => {
    dispatch({ type: "NEXT_STEP" });
  }, [dispatch]);

  const handleBack = useCallback(() => {
    dispatch({ type: "PREV_STEP" });
  }, [dispatch]);

  const handleComplete = useCallback(() => {
    // dispatch({ type: "COMPLETE_FORM", payload: state.formState });
  }, []);

  const steps = useMemo(() => {
    return [
      {
        label: "Detalhes",
        content: <WorkspaceUpsertStep1Details onBack={handleBack} onNext={handleNext} />,
      },
      {
        label: "Avatar",
        content: <WorkspaceUpsertStep2Photo onBack={handleBack} onNext={handleNext} />,
      },
      {
        label: "Personalização",
        content: <WorkspaceUpsertStep3 onBack={handleBack} onNext={handleComplete} />,
      },
    ];
  }, [handleBack, handleNext, handleComplete]);

  // if (isComplete) {
  //   return <JobPreview />;
  // }

  return (
    <>
      <Stepper
        activeStep={state.activeStep}
        orientation="vertical"
        sx={{
          "& .MuiStepConnector-line": {
            borderLeftColor: "divider",
            borderLeftWidth: 2,
            ml: 1,
          },
        }}
      >
        {steps.map((step, index) => {
          const isCurrentStep = state.activeStep === index;

          return (
            <Step key={step.label}>
              <StepLabel StepIconComponent={StepIcon}>
                <Typography sx={{ ml: 2 }} variant="overline">
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent
                sx={{
                  borderLeftColor: "divider",
                  borderLeftWidth: 2,
                  ml: "20px",
                  ...(isCurrentStep && {
                    py: 4,
                  }),
                }}
              >
                {step.content}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};
